export const KAMFormStatuses = {
  Concept: 1,
  Reported: 2,
  Closed: 3
}

export const KAMFormStatusLabels = {
  1: 'Concept',
  2: 'Gemeld bij KAM-manager',
  3: 'Afgehandeld'
}

export const KAMFormStatusLabelColors = {
  1: '#ffc107',
  2: 'red',
  3: 'green'
}

export const OpenKAMFormStatuses = [
  KAMFormStatuses.Concept,
  KAMFormStatuses.Reported
]

export const ClosedKAMFormStatuses = [
  KAMFormStatuses.Closed
]
